<script>
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  name: 'PDFMixin',
  data: () => ({
    logoElement: null,
    logoUrl: '',
  }),
  methods: {
    getScaleFactor() {
      return 0.4762626262626263
    },

    formatPDFLogo(originWidth, originHeight) {
      const result = {
        smallLogoHeight: 0,
        smallLogoWidth: 0,
        mainLogoHeight: 0,
        mainLogoWidth: 0,
        canvasLogoHeight: 0,
        canvasLogoWidth: 0,
        smallCanvasLogoHeight: 0,
        smallCanvasLogoWidth: 0,
      }

      if (originHeight > 0 && originWidth > 0) {
        if (originWidth > originHeight) {
          result.mainLogoWidth = 200
          result.mainLogoHeight = (originHeight * 200) / originWidth

          result.smallLogoWidth = 150
          result.smallLogoHeight = (originHeight * 150) / originWidth
        } else if (originWidth < originHeight) {
          result.mainLogoHeight = 100
          result.mainLogoWidth = (originWidth * 100) / originHeight

          result.smallLogoHeight = 70
          result.smallLogoWidth = (originWidth * 70) / originHeight
        } else {
          result.mainLogoWidth = 100
          result.mainLogoHeight = 100

          result.smallLogoWidth = 100
          result.smallLogoHeight = 100
        }
      }

      if (originHeight >= 800 || originWidth >= 800) {
        result.canvasLogoHeight = result.mainLogoHeight
        result.canvasLogoWidth = result.mainLogoWidth
        result.smallCanvasLogoHeight = result.smallLogoHeight
        result.smallCanvasLogoWidth = result.smallLogoWidth
      }

      const mmUnit = 0.2645833333

      result.mainLogoWidth *= mmUnit
      result.mainLogoHeight *= mmUnit
      result.smallLogoWidth *= mmUnit
      result.smallLogoHeight *= mmUnit

      return result
    },
    getLogoCanvas() {
      const result = {
        logoCanvas: null,
        formattedLogoSize: null,
      }

      let formattedLogoSize = {}

      if (this.logoElement !== undefined && this.logoElement !== null) {
        formattedLogoSize = this.formatPDFLogo(this.logoElement.width, this.logoElement.height)
        result.formattedLogoSize = formattedLogoSize

        if (
          formattedLogoSize.canvasLogoHeight !== 0 ||
          formattedLogoSize.canvasLogoWidth !== 0 ||
          formattedLogoSize.smallCanvasLogoHeight !== 0 ||
          formattedLogoSize.smallCanvasLogoWidth !== 0
        ) {
          result.logoCanvas = document.createElement('canvas')
          let ctx = result.logoCanvas.getContext('2d')

          result.logoCanvas.width = this.logoElement.width
          result.logoCanvas.height = this.logoElement.height

          const scale = this.getScaleFactor()

          const dWidth = this.logoElement.width * scale
          const dHeight = this.logoElement.height * scale

          result.logoCanvas.style.width = dWidth + 'px'
          result.logoCanvas.style.height = dHeight + 'px'
          ctx.drawImage(this.logoElement, 0, 0)
        }
      }

      return result
    },
    getInvoicePDF(orderData) {
      const pdf = new jsPDF()
      pdf.setFont('NotoSansTC-Regular')
      pdf.setFontSize(12)

      // calculate logo height
      let formattedLogoSize = {}
      let logoCanvas = null

      const getLogoCanvas = this.getLogoCanvas()
      formattedLogoSize = getLogoCanvas.formattedLogoSize
      logoCanvas = getLogoCanvas.logoCanvas

      const horizontalBorder = 10
      var currentCursorPosition = 0
      const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth()
      const fullCellWidth = pageWidth - horizontalBorder * 2

      autoTable(pdf, {
        // startY: logoPaddingTop,
        margin: { horizontal: horizontalBorder },
        columnStyles: {
          text: {
            cellPadding: { horizontal: 0 },
            cellWidth: fullCellWidth / 2,
            fontSize: 20,
            halign: 'left',
            font: 'NotoSansTC-Regular',
          },
          logo: {
            cellPadding: { horizontal: 0 },
            cellWidth: fullCellWidth / 2,
          },
        },
        showHead: 'never',
        showFoot: 'never',
        theme: 'plain',
        bodyStyles: { valign: 'bottom' },
        columns: [
          { header: '', dataKey: 'text' },
          { header: '', dataKey: 'logo' },
        ],
        body: [{ text: 'INVOICE' }],

        didDrawCell: headData => {
          // add logo to first page
          if (headData.column.index === 1) {
            headData.doc.addImage(
              logoCanvas !== null ? logoCanvas : this.logoElement,
              'PNG',
              headData.cell.x + headData.cell.minWidth - formattedLogoSize.mainLogoWidth,
              headData.cell.y,
              formattedLogoSize.mainLogoWidth,
              formattedLogoSize.mainLogoHeight,
              '',
              'MEDIUM',
            )
          }

          currentCursorPosition = headData.cell.y + headData.cell.height + 20
        },
      })

      // info grid
      autoTable(pdf, {
        startY: currentCursorPosition,
        margin: { horizontal: horizontalBorder },
        columnStyles: {
          col1: { cellWidth: fullCellWidth * 0.15, valign: 'middle', cellPadding: { top: 0, bottom: 1 } },
          col2: { cellWidth: fullCellWidth * 0.85, valign: 'middle', cellPadding: { top: 0, bottom: 1 } },
        },
        showHead: 'never',
        showFoot: 'never',
        theme: 'plain',
        bodyStyles: { valign: 'top', fontSize: 9, font: 'NotoSansTC-Regular' },
        columns: [
          { header: '', dataKey: 'col1' },
          { header: '', dataKey: 'col2' },
        ],
        body: [
          {
            col1: '訂單日期:',
            col2: this.$Formatter.displayCreateDate(orderData.create_date),
          },
          {
            col1: '單號:',
            col2: `#${orderData.id}`,
          },
          {
            col1: `會員姓名:`,
            col2: this.$validate.DataValid(orderData.username) ? orderData.username : '-',
          },
          {
            col1: `會員電郵:`,
            col2: this.$validate.DataValid(orderData.email) ? orderData.email : '-',
          },
          {
            col1: `會員電話:`,
            col2: this.$validate.DataValid(orderData.phone) ? orderData.phone : '-',
          },
        ],
        didParseCell: infoData => {
          infoData.doc.setLineHeightFactor(1.5)
        },
        didDrawCell: infoData => {
          currentCursorPosition = infoData.cell.y + infoData.cell.height + 10
        },
      })

      var count = 0

      const productBody = [
        {
          col1: orderData.package_name,
          col2: `HK$${orderData.price}`,
        },
      ]

      if (this.$validate.DataValid(orderData.couponList)) {
        for (let i = 0; i < orderData.couponList.length; i++) {
          productBody.push({
            col1: { content: `- ${orderData.couponList[i].name} (${orderData.couponList[i].qty}張)`, styles: { fontSize: 8 } },
            col2: '',
          })
        }
      }

      // product grid
      autoTable(pdf, {
        startY: currentCursorPosition,
        margin: { horizontal: horizontalBorder },
        headStyles: {
          font: 'NotoSansTC-Regular',
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: 'rgb(37, 37, 37)',
          textColor: 'rgb(37, 37, 37)',
          fontSize: 10,
          cellPadding: 3,
        },

        columnStyles: {
          col1: {
            cellWidth: fullCellWidth * 0.75,
            valign: 'middle',
            halign: 'left',
            lineWidth: {
              left: 0.2,
              right: 0.2,
            },
            lineColor: 'rgb(37, 37, 37)',
            textColor: 'rgb(37, 37, 37)',
            cellPadding: { top: 2, bottom: 0, left: 3, right: 3 },
            fontSize: 10,
          },
          col2: {
            cellWidth: fullCellWidth * 0.25,
            valign: 'middle',
            halign: 'left',
            lineWidth: {
              right: 0.2,
            },
            lineColor: 'rgb(37, 37, 37)',
            textColor: 'rgb(37, 37, 37)',
            cellPadding: { top: 2, bottom: 0, left: 3, right: 3 },
            fontSize: 10,
          },
        },
        theme: 'plain',
        showFoot: 'never',
        bodyStyles: { valign: 'middle', fontSize: 9, font: 'NotoSansTC-Regular' },
        columns: [
          { header: '套票', dataKey: 'col1' },
          { header: '價錢', dataKey: 'col2' },
        ],
        styles: { overflow: 'linebreak' },
        body: productBody,
        didParseCell: infoData => {
          if (infoData.section === 'head') {
          } else {
            if (infoData.column.index === 0) {
              count++
            }

            if (this.$validate.DataValid(orderData.couponList)) {
              if (count === orderData.couponList.length + 1) {
                infoData.cell.styles.lineColor = 'rgb(37, 37, 37)'
                infoData.cell.styles.lineWidth = { left: 0.2, bottom: 0.2, right: 0.2 }
              }
            } else {
              if (count === +1) {
                infoData.cell.styles.lineColor = 'rgb(37, 37, 37)'
                infoData.cell.styles.lineWidth = { left: 0.2, bottom: 0.2, right: 0.2 }
              }
            }
          }

          infoData.doc.setLineHeightFactor(1.5)
        },

        didDrawCell: infoData => {
          currentCursorPosition = infoData.cell.y + infoData.cell.height
        },
      })

      const totalContent = [{ col1: '', col2: '小計', col3: `HK$${orderData.price}` }]

      if (orderData.promo_code) {
        totalContent.push({
          col1: '',
          col2: '折扣',
          col3: `HK$${orderData.discount}`,
        })
      }

      totalContent.push({
        col1: '',
        col2: '總額',
        col3: `HK$${orderData.total}`,
      })

      autoTable(pdf, {
        startY: currentCursorPosition,
        margin: { horizontal: horizontalBorder },

        columnStyles: {
          col1: {
            cellWidth: fullCellWidth * 0.75,
            valign: 'middle',
            lineColor: 'rgb(255,255,255)',
            halign: 'right',
            cellPadding: { top: 2, bottom: 2, left: 3, right: 3 },
          },
          col2: {
            cellWidth: fullCellWidth * 0.1,
            valign: 'middle',
            lineColor: 'rgb(255,255,255)',
            halign: 'left',
            cellPadding: { top: 2, bottom: 2, left: 1, right: 3 },
          },
          col3: {
            cellWidth: fullCellWidth * 0.15,
            valign: 'middle',
            lineColor: 'rgb(255,255,255)',
            halign: 'right',
            cellPadding: { top: 2, bottom: 2, left: 3, right: 1 },
          },
        },
        theme: 'plain',
        showHead: 'never',
        showFoot: 'never',
        bodyStyles: { valign: 'middle', fontSize: 9, font: 'NotoSansTC-Regular' },
        columns: [
          { header: '', dataKey: 'col1' },
          { header: '', dataKey: 'col2' },
          { header: '', dataKey: 'col3' },
        ],
        styles: { overflow: 'linebreak' },
        body: totalContent,

        didDrawCell: infoData => {
          currentCursorPosition = infoData.cell.y + infoData.cell.height
        },
      })

      return pdf
    },
  },
  mounted() {
    this.logoUrl = require('@/assets/images/logo.png')
    this.logoElement = document.createElement('img')
    this.logoElement.setAttribute('src', this.logoUrl)
    this.logoElement.crossOrigin = 'anonymous'
  },
}
</script>
