<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading">
      <template #rightSlot>
        <v-btn
          depressed
          color="primary accent-4"
          class="white--text"
          :loading="$store.getters.isLoading"
          @click="downloadInvoice()"
          >下載發票</v-btn
        >
      </template>
    </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">訂單資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="訂單日期" :fieldValue.sync="formData.create_date" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="會員姓名" :fieldValue.sync="formData.username" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="會員電話" :fieldValue.sync="formData.phone" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="折扣" :fieldValue.sync="formData.discount" disabled prefix="$" />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="折扣（優惠碼）" :fieldValue.sync="formData.promo_code" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="總額" :fieldValue.sync="formData.total" disabled prefix="$" />
            </v-col>
            <v-col cols="12" md="6">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$orderStatusOptions" required />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">套票資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="套票ID" :fieldValue.sync="formData.coupon_group_id" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="標題" :fieldValue.sync="formData.package_name" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="套票價錢" :fieldValue.sync="formData.price" disabled prefix="$" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">換領券</v-card-title>

          <v-row class="ma-0" v-for="(item, index) of couponList" :key="index">
            <v-col cols="6">
              <form-input label="名稱" :fieldValue.sync="item.name" disabled />
            </v-col>
            <v-col cols="6">
              <form-input label="數量" :fieldValue.sync="item.qty" disabled />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import PDFMixin from '@/mixins/PDFMixin.vue'
require('@/assets/pdf-fonts/NotoSansTC-Regular-normal')
require('@/assets/pdf-fonts/NotoSansTC-Medium-bold')

export default {
  name: 'OrderDetail',
  mixins: [PDFMixin],
  components: {
    FormInput,
    FormSelect,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      create_date: '',
      username: '',
      phone: '',
      email: '',
      discount: '',
      promo_code: '',
      total: '',
      status: '',
      coupon_group_id: null,
      package_name: '',
      price: '',
    },

    couponList: [],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetOrderById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }
        this.formData.create_date = this.$Formatter.displayCreateDate(data.create_date)
        if (data.user_data) {
          this.formData.username = data.user_data.name
          this.formData.phone = data.user_data.phone
          this.formData.email = data.user_data.email
        }

        if (data.promo_code_data) {
          this.formData.promo_code = data.promo_code_data.discount_code
        }

        if (data.coupon_group_data) {
          this.formData.package_name = data.coupon_group_data.name
          this.formData.price = data.coupon_group_data.price
        }

        if (data.coupon_group_content) {
          this.couponList = data.coupon_group_content.map(el => {
            let obj = {
              name: '',
              qty: el.qty,
            }

            const found = data.user_coupon ? data.user_coupon.find(e => e.coupon_id === el.coupon_id) : null
            if (found) {
              obj.name = found.coupon_data.name
            }

            return obj
          })
        }

     
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'UserCoupon',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'UserCoupon',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
      this.$store.dispatch('setLoading', true)
      const user = this.getCurrentUserData()
      try {
        await this.$Fetcher.UpdateOrderStatus(this.id, this.formData.status, user.id, user.token)

        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleSnack', {
          type: 'success',
          message: '儲存成功',
          refresh: true,
        })
      } catch (err) {
        this.setAlert({
          title: '儲存失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },

    downloadInvoice() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      let data = this.$common.duplicateData(this.formData)
      data['couponList'] = this.couponList
      data['id'] = this.id
      const pdf = this.getInvoicePDF(data)
      pdf.save(`invoice_${this.id}.pdf`)
      this.$store.dispatch('setLoading', false)
    },


  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
